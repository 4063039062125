<template>
  <div>
    <Layout>
    <PageHeader :title="title" :items="items" />

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
          <div class="add_coin_form_box mb-3">
            <div class="row middle-row align-items-center">
              <div class="col-md-12">
                <div class="form-box">
                  <!-- ========== New FORM Box ======== -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3">
              <div class="input-group mb-3">

                <input type="text" v-model="searchParam" @keydown="debounceSearch($event)" class="form-control" aria-label="Text input with" placeholder="Search">
                <div class="input-group-prepend search">
                  <div class="input-group-text rounded-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path></svg>
                  </div>
                </div>
              </div>
              </div>
            </div>
            
            <hr />

            <!-- Button trigger modal -->

            <div class="table-responsive">
              <table class="table table-bordered text-center">
                <thead class="text-nowrap">
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">User Id</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Claim date</th>
                  </tr>
                </thead>
                <tbody
                  v-if="BirBalances.length != 0"
                  class="table-group-divider text-nowrap"
                >
                  <tr v-for="(bal, i) in BirBalances" :key="i">
                    <td>{{ perPageData * (current_page-1) +i+1 }}</td>
                    <td>{{ bal.user_id }}</td>
                    <td>{{ bal.user.user_name }}</td>
                    <td>{{ bal.user.email }}</td>
                    <td>{{ bal.credit_amount }} TACC</td>
                    <td> <span class="p-2 rounded text-white" :style="(bal.status== 'claimed') ? 'background: red' : 'background: green'" >success</span> </td>
                    <td>
                      {{
                         getDate(bal.created_at)
                          
                      }}
                    </td>
                    <!-- <td>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style="fill: red"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                          />
                        </svg>
                      </span>
                    </td> -->
                  </tr>
                </tbody>
                <tbody v-else class="table-group-divider text-nowrap">
                  <div class="">No data Found</div>
                </tbody>
                
              </table>
               <div class=" d-flex justify-content-end">
                        <pagination
                          v-model="current_page"
                          :records="totalData"
                          :per-page="perPageData"
                          :options="options"
                          @paginate="ClaimHistory"
                        />
            </div>
            
            </div>
          </div>
        </div>
      </div>
      <!-- Button trigger modal -->
    </Layout>
    <!-- <Dialog header="Edit" :visible.sync="display"> -->
    <!-- ============================================================= -->

    <!-- </Dialog> -->
  </div>
</template>

<script>
// import Dialog from "primevue/dialog";
import Layout from "../../layouts/main";
import Pagination from 'vue-pagination-2';

import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
// import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";

// const checkDate = function (dt1, dt2) {
//   return new Date(dt1).getTime() < new Date(dt2).getTime();
// };
// import _ from "lodash";

export default {
  page: {
    title: "Birake Balances",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Pagination,
    // Dialog,
    // Multiselect,
  },
  setup() {
    return {};
  },
  data() {
    return {
      items: [
        {
          text: "Balances",
          href: "#",
        },
        {
          text: "Claimed/Unclaimed Balances",
          active: true,
        },
      ],
      BirBalances: [],
      title: "Claim history",
      amount: "",
      user_name: "",
      status: "",
      email: "",
      current_page: 1,
      totalData:100,
      perPageData:15,
      options: {
        edgeNavigation: true,
        chunksNavigation: false,
        chunk: 5,
        texts: false,
        format: false,
      },
      searchParam:''
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.ClaimHistory();
  },
  methods: {
    debounceSearch(event) {
      
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        
        this.searchParam = event.target.value
        this.ClaimHistory();
      }, 1000)
    }, 
    getDate(inputDate) {
      const date = new Date(inputDate);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      const formattedDate = `${day}:${month}:${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
    getCustomUtcDate(fulldate = null) {
      let date = fulldate ? new Date(parseFloat(fulldate)) : new Date();
      console.log({ date });

      let day = date.getUTCDate();
      let month = date.getUTCMonth() + 1;
      let year = date.getUTCFullYear();
      let hour = date.getUTCHours();
      let min = date.getUTCMinutes();
      let sec = date.getUTCSeconds();

      return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
    },
    async ClaimHistory() {
      let res = await ApiClass.getNodeRequest(
        `user-crypto/admin/user-claim-status?amount=${this.amount}&user_name=${this.user_name}&status=${this.status}&email=${this.email}&page=${this.current_page}&per_page=${this.perPageData}&searchParam=${this.searchParam}`,
        true
      );
      let response = res.data;
      if (response.status_code == "1") {
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: response.message,
        //   showConfirmButton: true,
        //   timer: 5000,
        // });

        this.BirBalances = response.data.data;
        this.totalData =  response.data.total;

      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: response.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
  },
};
</script>

<style scoped>
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #556ee6;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.add_coin_form_box {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  padding: 15px 15px;
}

.form-group.btn-submit {
  text-align: center;
}

.form-group .currency-image {
  text-align: center;
  max-width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 26px;
  padding: 0;
  margin-top: 18px;
  margin-left: 0;
}

.form-group .currency-image img {
  max-width: 39px;
  margin-top: 5px;
}

.border_box {
  border: 1px solid #ebecf1;
  border-radius: 5px;
}
</style>
